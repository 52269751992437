/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/assets/css/reset.css';

// import './src/components/layout.css';
import "./src/components/editors/gl.scss"

import 'bootstrap/dist/css/bootstrap.min.css';
import './src/assets/css/spacing.css';

import './src/assets/css/line-awesome.min.css';
import './src/assets/css/themify-icons.css';
import './src/assets/css/animate.css';
import 'react-accessible-accordion/dist/fancy-example.css';
// import 'react-modal-video/scss/modal-video.scss'; 

import 'react-alice-carousel/lib/alice-carousel.css';
import './src/assets/css/custom-react-alice-carousel.css';
 
// import 'antd/dist/antd.css';
import './src/assets/css/theme.min.css';

/* country flags */
import './src/assets/css/flag-icon.css';

import "./src/assets/css/editorPortions.scss"
import "./src/assets/css/style.scss"
import "./src/assets/css/mobile.scss"