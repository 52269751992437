module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DevMastery Labs","short_name":"DevMastery","start_url":"/","background_color":"#060927","theme_color":"#060927","display":"minimal-ui","icon":"src/images/devmastery-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f37d8960c4321bb4d5fb68ed5e0f7aa3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/macbook/Projects/PLATFORM_LANDING/src/intl","languages":["fr","en"],"defaultLanguage":"fr","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
